/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";

@import "~@ionic/angular/css/flex-utils.css";

:root {
  --ion-color-primary: #112549;
  --ion-color-primary-rgb: 63, 140, 255;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #377be0;
  --ion-color-primary-tint: #5298ff;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-success: #00d097;
}

ion-toast.custom-toast::part(button) {
  border-left: 1px solid #d2d2d2;
  color: #030207;
  font-size: 15px;
}

body {
  --ion-background-color: white !important;
}
.btn-Logout {
  background-color: white !important;
  color: black;
  width: 140px;
  height: 40px;
  border-radius: 5px;
  font-size: 22px;
  font-weight: bolder;
}

.btn-signup {
  width: 180px;
  background-color: #112549;
  color: white;
  height: 45px;
  border-radius: 14px;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 900;
}
.ionicon.s-ion-icon {
  margin-top: 3px;
}
.btn-login {
  width: 150px;
  color: black;
  height: 45px;
  border-radius: 14px;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 900;
  background-color: white;
  border: 2px solid black;
}
.lbl-description {
  color: rgb(104, 104, 104);
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 40px;
}
.main-img {
  margin-left: 50px;
  position: relative;
  margin-top: 100px;
  margin-right: 50px;
}
.heading-main {
  color: black;
  font-size: 48px;
  font-weight: bolder;
}
.left-signup-div {
  background-color: #112549;
  border-radius: 16px;
}

.pdl-10 {
  padding-left: 10px;
}
// .button-round {
//   box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
//   border-radius: 14px;
//   height: 48px;
// }
ion-checkbox {
  --border-color: #222428;
}

ion-grid {
  overflow: auto;
  margin-inline: unset;
}
.auth-back-button {
  --color: --ion-color-dark;
}
.auth-toolbar {
  --background: --ion-color-ligth;
}

.auth-header::after {
  background-image: none;
  background-repeat: repeat-x;
  content: none;
}
ion-menu {
  --border: 1pxsolidvar
    (
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
  --side-min-width: 270px;
  --side-max-width: 20% !important;
}

.menu-bottom-image {
  position: fixed;
  bottom: 0;
  --border-style: none;
  width: 100%;
  text-align: center;
}

ion-loading.custom-loading {
  --background: transparent;
  --spinner-color: var(--ion-color-primary);
  color: #1c6dff;
  transform: scale(2);
}

.loading-wrapper {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}

ion-toolbar ion-title {
  color: black !important;
  font-weight: 700;
  font-size: 22px;
  margin-left: 10px;
}

.ng-valid[required],
.ng-valid.required {
  border: 1px solid #d8e0f0;
}

form .ng-untouched {
  border: 1px solid #d8e0f0 !important;
}

.ng-invalid:not(form) {
  border: 1px solid var(--highlight-color-invalid);
}

form ion-item {
  --border-style: none;
  margin-bottom: 16px;

  ion-input,
  ion-textarea,
  ion-select {
    border: 1px solid #d8e0f0;
    box-shadow: 0px 1px 2px rgba(160, 46, 46, 0.222);
    border-radius: 14px;
    margin-top: 8px;
    input,
    textarea {
      height: 40px;
      padding-left: 16px !important;
    }
  }
}

ion-input ion-icon {
  position: absolute;
  position: absolute;
  right: 0;
  margin-right: 10px;
  font-size: x-large;
  cursor: pointer;
}
ion-input ion-icon:hover {
  color: var(--ion-color-primary);
}

.view-password-eye {
  place-self: self-end;
  margin-left: 8px;
}
.icon44px {
  font-size: 44px;
}
ion-card.card {
  cursor: pointer;
}
ion-card.card:hover {
  border-color: var(--ion-color-primary);
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 16px;
  border-style: inset;
  border-width: 1px;
}

ion-card.selected-card {
  border-color: var(--ion-color-primary);
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 16px;
  border-style: dotted;
  border-width: 1px;
  background: #ebf6ff;
  ion-card-content {
    background: #ebf6ff !important;
  }
}

ion-content.page-content {
  --background: #f4f9fd !important;
}

ion-card.card {
  --background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 24px;
  div {
    background-color: #f4f9fd !important;
    border-radius: 24px;
    margin: 3%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.px-74 {
  height: 74px;
  width: 74px;
}

ion-icon.icon-hover:hover {
  border-color: var(--ion-color-primary);
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 16px;
  border-style: inset;
  border-width: 1px;
}
.action-sheet-title,
.action-sheet-button,
.action-sheet-icon {
  color: black !important;
}

.toast-wrapper {
  --border-color: #1dc194;
  --border-radius: 2px solid #1dc194;
  --border-style: 5px 4px 29px rgba(19, 35, 79, 0.12);
  --border-width: 16px;
  --border-shadow: 5px 4px 29px rgba(19, 35, 79, 0.12);
}
ion-toast.custom-toast::part(container) {
}

ion-toast.custom-toast::part(header) {
  color: white !important;
}

ion-toast.custom-toast::part(message) {
  color: white !important;
}

ion-toast.custom-toast::part(button) {
  color: white !important;
  border-left: unset;
  font-weight: 500px;
}

.logo-img {
  cursor: pointer;
  img {
    height: 210px !important;
    width: 35px !important;
  }
}
.card-with-image-hover:hover .hidden_img {
  color: black;
  display: block;
  height: 30px;
  margin: 10px;
  width: 30px;
}

.hidden_img:hover {
  color: var(--ion-color-primary) !important;
}

.card-with-image-hover .hidden_img {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.unset-margin-top {
  margin-top: unset !important;
}
.no-bottom-margin {
  margin-bottom: 0px !important;
}

.datatable-header {
  padding: 10px;
}
.ngx-datatable .datatable-body {
  background-color: #f4f9fc !important;
  border-radius: 22px !important;
}

.ngx-datatable.fixed-row .datatable-scroll,
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
  height: 62px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.datatable-grid {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 24px;
}
.searchbar-input.sc-ion-searchbar-md {
  --box-shadow: none !important;
}

.custom-ion-thumbnail {
  --size: 140px;
  --border-radius: 14px;
  cursor: pointer;
}
.profile-picture-header-menu {
  height: 40px;
  width: 40px;
}
ion-item-divider {
  opacity: 0.1;
  border-bottom: 1px solid #14121d;
}
ion-button.save-with-icon {
  color: white !important;
  --border-radius: 14px !important;
  height: 44px;
  width: 100%;
  --background: var(--ion-color-primary) !important;
  ion-label {
    margin-right: auto;
    padding-left: 10px;
  }
  ion-icon {
    padding-right: 10px;
    font-size: x-large;
  }
}
.underline {
  text-decoration: underline;
  cursor: pointer;
}
.underline:hover {
  color: var(--ion-color-primary);
}
.no-text-decoration {
  text-decoration: none;
}
.full-height {
  height: 100%;
  width: -webkit-fill-available;
}
.full-width {
  width: 100%;
}
.no-padding {
  padding: 0px;
}
.no-padding-left {
  padding: 0px;
}
.no-vertical-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}
.no-vertical-margin {
  margin-top: 0px;
  margin-bottom: 0px;
}
.auth-button-mb {
  height: 42px;
}

.new-user-text {
  color: #939393 !important;
  padding: 0px;
  margin: 0px;
}

.login-btn-mb {
  padding-left: 18px;
  padding-right: 18px;
}

.dashboard-tile {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  min-height: 174px;
}

ion-title.color-light {
  color: var(--ion-color-light) !important;
  --color: var(--ion-color-light) !important;
}
.logout-text {
  color: #9a4a4a !important;
}

ion-button.submit-btn {
  color: white !important;
  --border-radius: 14px !important;
  height: 44px;
}
.ion-padding-horizontal-10px {
  padding-left: 10px;
  padding-right: 10px;
}
.password-eye-icon {
  align-self: self-end;
  right: 50px;
  position: fixed;
  cursor: pointer;
  z-index: 10;
}
